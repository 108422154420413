import { combineReducers } from "redux";
// import { persistReducer } from 'redux-persist';
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
// slices
import auth from "./slices/auth";
import register from "./slices/register";
import forgot from "./slices/forgot";
import notification from "./slices/notification";
import product from "./slices/product";
import profile from "./slices/profile";

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem(_key) {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem(_key) {
    return Promise.resolve();
  },
});

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

// const productPersistConfig = {
//   key: "product",
//   storage,
//   keyPrefix: "redux-",
//   whitelist: ["sortBy", "checkout"],
// };

const rootReducer = combineReducers({
  auth: auth,
  register: register,
  forgot: forgot,
  notification: notification,
  product: product,
  profile: profile,
});

export { rootPersistConfig, rootReducer };
