import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { locales } from "../i18n";

const LocaleContext = React.createContext({
  locale: locales[0],
  changeLocale: (locale) => {},
});

export const useLocale = () => {
  return useContext(LocaleContext);
};

const LocaleProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState("mn");

  const changeLocale = useCallback(
    (_locale) => {
      i18n.changeLanguage(_locale.key);
      setLocale(_locale);
    },
    [i18n]
  );

  useEffect(() => {
    const storeLocale = localStorage.getItem("i18nextLng");
    const selectedLocaleObject = locales.find(
      (l) =>
        l.key ===
        (locales.map((l) => l.key).includes(storeLocale) ? storeLocale : "mn")
    );
    changeLocale(selectedLocaleObject);
  }, [changeLocale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale,
        locales: locales,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
