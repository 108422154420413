import { createSlice } from "@reduxjs/toolkit";
import { tmpTokenKey, tokenKey } from "../../utils/constants";
// utils
import { dispatch } from "../store";

//services
import AuthService from "../../services/AuthService";

import { v4 as uuidv4 } from "uuid";
import { jwtDecode } from "jwt-decode";
// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  success: false,
  logged: false,
  isVerifyDevice: false,
  username: "",
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      console.log("error ni yu ireed bna", action);
      state.error = action.payload;
    },
    //Login success
    loginSuccess(state, action) {
      console.log("action ni yu baina", action);
      state.loading = false;
      state.success = true;
      state.username = action.payload.params.username;
      // state.logged = true;
      if (action.payload.response.code === 200) {
        state.isVerifyDevice = true;
        state.logged = true;
        localStorage.setItem(tokenKey, action.payload.response.token);
        // action.payload.navigate("/home", { replace: true });
        window.location.replace("/home");
      } else {
        state.isVerifyDevice = false;
        localStorage.setItem(tmpTokenKey, action.payload.response.token);
        action.payload.navigate("/not-verify-device", { replace: false });
      }
      console.log("payload", action);
      // action.payload.navigate("/home", { replace: true });
    },
    //Login success
    deviceOtpVerifySuccess(state, action) {
      console.log("deviceOtpVerifySuccess yu baina", action);
      state.loading = false;
      state.success = true;
      state.logged = true;
      state.isVerifyDevice = false;
      console.log("payload", action);
      localStorage.setItem(tokenKey, action.payload.response.token);
      localStorage.removeItem(tmpTokenKey);
      // action.payload.navigate("/home", { replace: true });
      window.location.replace("/home");
    },
    deviceOtpSuccess(state, action) {
      console.log("action payload", action.payload);
      state.loading = false;
      action.payload.navigate("/verify-device");
    },
    //Check login
    checkLogin(state, action) {
      const token = localStorage.getItem(tokenKey);
      if (token) {
        const decoded = jwtDecode(token);

        console.log(decoded);
        console.log("action ni yu bain", decoded);
        state.logged = true;
        state.username = decoded.username;
      } else {
        action.payload("login", { replace: true });
      }
    },
    //Logout
    logout(state, action) {
      localStorage.removeItem(tokenKey);
      state.logged = false;
      action.payload("login", { replaceAll: true });
    },
    //Login hide error
    hideError(state) {
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hideError, checkLogin, logout } =
  slice.actions;

// // ----------------------------------------------------------------------
const getDeviceId = () => {

  let deviceId = navigator.userAgent + "-" + navigator.platform + document.cookie
    .split("; ")
    .find((row) => row.startsWith("deviceId="))
    ?.split("=")[1];

  if (!deviceId) {
    deviceId = uuidv4(); // Generate a new UUID
    document.cookie = `deviceId=${deviceId}; path=/;`;
  }
  console.log(deviceId);
  return deviceId;
};

export function loginThunk({ params, navigate }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("getDeviceId", getDeviceId());
      params.userAgent = getDeviceId();
      const response = await AuthService.login(params);

      dispatch(slice.actions.loginSuccess({ response, navigate, params }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deviceOtpVerify(body, navigate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("getDeviceId", getDeviceId());
      body.userAgent = getDeviceId();
      const tmpToken = localStorage.getItem(tmpTokenKey);
      let config = {};
      try {
        if (tmpToken) {
          config = {
            ...config,
            headers: {
              Authorization: `Bearer ${tmpToken}`,
            },
          };
          console.log("Updated Config:", config);
        }
      } catch (ex) {
        console.error("Exception during config setup:", ex);
      }
      const response = await AuthService.deviceOtpVerify(body, config);
      dispatch(slice.actions.deviceOtpVerifySuccess({ response, navigate }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deviceOtp(body, navigate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("getDeviceId", getDeviceId());
      body.userAgent = getDeviceId();
      const tmpToken = localStorage.getItem(tmpTokenKey);
      let config = {};
      try {
        if (tmpToken) {
          config = {
            ...config,
            headers: {
              Authorization: `Bearer ${tmpToken}`,
            },
          };
          console.log("Updated Config:", config);
        }
      } catch (ex) {
        console.error("Exception during config setup:", ex);
      }
      await AuthService.deviceOtp(body, config);
      dispatch(slice.actions.deviceOtpSuccess({ navigate }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
