import { Fragment, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as Close } from "../../assets/icon/cross.svg";
import PropTypes from "prop-types";
import { TbArrowLeft, TbArrowRight, TbX } from "react-icons/tb";
export default function Modal(props) {
  const {
    isOpen,
    onClose = () => {},
    withCloseButton = false,
    withBackButton = false,
    onBackClick,
    title,
    children,
    size = "md",
  } = props;

  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={() => {
        // !withCloseButton &&
        onClose();
      }}
      className="relative z-10 focus:outline-none"
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/60">
        <div className="flex min-h-full items-center justify-center p-4 select-none">
          <DialogPanel
            transition
            className={twMerge([
              "w-full border border-stroke rounded-xl bg-white/80 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0",
              size === "md" && "max-w-md",
              size === "lg" && "max-w-lg",
              size === "xl" && "sm:max-w-xl max-w-full",
              size === "2xl" && "min-[672px]:max-w-2xl max-w-full",
              size === "3xl" && "md:max-w-3xl max-w-full",
              size === "4xl" && "min-[896px]:max-w-4xl max-w-full",
              size === "5xl" && "lg:max-w-5xl max-w-full",
            ])}
          >
            <DialogTitle
              as="h3"
              className={twMerge([
                "text-page/7 font-medium text-primary flex items-center space-x-4",
                withCloseButton && "justify-between",
              ])}
            >
              {withBackButton && (
                <button className="outline-none" onClick={onBackClick}>
                  <TbArrowLeft size={20} />
                </button>
              )}
              <div>{title}</div>
              {withCloseButton && (
                <button className="outline-none" onClick={onClose}>
                  <TbX size={20} />
                </button>
              )}
            </DialogTitle>
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

Modal.propTypes = {
  title: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  withCloseButton: PropTypes.bool,
  withBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  size: PropTypes.string,
  // children: PropTypes.node,
};
