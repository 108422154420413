import { Controller, useForm } from "react-hook-form";
import Button from "../../../base-components/Button";
import Checkbox from "../../../base-components/Checkbox";
import Typography from "../../../base-components/Typography";
import { twMerge } from "tailwind-merge";
import { dispatch, useSelector } from "../../../redux/store";
import { PulseLoader } from "react-spinners";
import { checkLogin, deviceOtp } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const RemindDevice = () => {
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      isRemind: true, // Default value for isRemind
      device: "mobile",
    },
  });
  const navigate = useNavigate();
  const { username, loading } = useSelector(state => state.auth)
  const onSubmit = (data) => {
    console.log(data, "datas");

    dispatch(deviceOtp({
      "addDevice": data.isRemind,
      "phoneNumber": username,
    }, navigate))
  };
  const { logged } = useSelector((state) => state.auth)
  const maskString = (str) => {
    if (str.length <= 2) {
      return str; // Don't mask if the string is too short
    }
    const firstChar = str.charAt(0);
    const lastChar = str.charAt(str.length - 1);
    const maskedPart = '*'.repeat(str.length - 2); // Calculate how many '*' to add
    return `${firstChar}${maskedPart}${lastChar}`;
  };
  useEffect(() => {
    if (logged) {
      // navigate('/home');
    }
  }, [logged])
  return (

    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-[333px] grid gap-8"
    >
      <Typography variant="Label">
        Та бүртгэлгүй төхөөрөмжөөс нэвтэрсэн тул дараах сувгуудаар өөрийгөө
        баталгаажуулна уу.
      </Typography>
      <div className="grid gap-4">
        <div className="flex items-center space-x-2 p-2 rounded-lg shadow-[0_2px_16px_-1px_rgb(0,0,0,0.1)]">
          <input
            type="radio"
            id="mobile"
            value="mobile"
            {...register("device")}
          />
          <label htmlFor="mobile">
            <Typography
              variant="Label"
              size="lg"
              className={twMerge([
                "text-gray",
                watch("device") === "mobile" && "text-primary",
              ])}
            >
              {maskString(username)} дугаарт код авах
            </Typography>
          </label>
        </div>
        {/* <div className="flex items-center space-x-2 p-2 rounded-lg shadow-[0_2px_16px_-1px_rgb(0,0,0,0.1)]">
          <input type="radio" id="mail" value="mail" {...register("device")} />
          <label
            htmlFor="mail"
            className={twMerge([
              "text-gray",
              watch("device") === "mail" && "text-primary",
            ])}
          >
            <Typography variant="Label" size="lg" className="text-gray">
              B******@gmail.com и-мейл хаягт код авах
            </Typography>
          </label>
        </div> */}
      </div>
      <Controller
        control={control}
        name="isRemind"

        render={({ field: { onChange, value } }) => (
          <Checkbox
            label="Төхөөрөмж сануулах"
            checked={value}
            onChange={onChange}
          />
        )}
      />

      <div
        className={twMerge(["justify-center mt-2 hidden", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <Button type="submit">Илгээх</Button>
    </form>
  );
};

export default RemindDevice;
