import { useEffect, useState } from "react";
import Modal from "../../../base-components/Modal";
import SavingsForm from "./modals/Form";
import SavingsInfo from "./modals/Info";
import Schedule from "./modals/Schedule";
import Contract from "./modals/Contract";

const CreateSavings = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step > 4) {
      onClose()
    }
  }, [step])
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Header"}
      withCloseButton={step === 1}
      withBackButton={step > 1}
      onBackClick={() => {
        setStep(step - 1);
      }}
      size={step === 4 ? "5xl" : "lg"}
    >
      {step === 1 && <SavingsInfo {...{ step, setStep }} />}
      {step === 2 && <SavingsForm {...{ step, setStep }} />}
      {step === 3 && <Schedule {...{ step, setStep }} />}
      {step === 4 && <Contract {...{ step, setStep }} />}
    </Modal>
  );
};

export default CreateSavings;
