import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"; // Import the icons

const ContactInfo = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white shadow-md rounded-lg p-6">
        {/* <h2 className="text-2xl font-bold mb-6">Холбоо барих мэдээлэл</h2> */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {" "}
          {/* Adjusted gap for smaller screens */}
          {/* Address Section */}
          <div>
            <h3 className="text-lg md:text-xl font-semibold mb-3">Хаяг</h3>
            <p className="text-font text-sm md:text-base">
              Улаанбаатар хот, Сүхбаатар дүүрэг, 1-р хороо, Энхтайваны өргөн
              чөлөө 16
            </p>
          </div>
          {/* Phone Section */}
          <div>
            <h3 className="text-lg md:text-xl font-semibold mb-3">Утас</h3>
            <p className="text-font text-sm md:text-base">+976 99111199</p>
          </div>
          {/* Email Section */}
          <div>
            <h3 className="text-lg md:text-xl font-semibold mb-3">И-мэйл</h3>
            <p className="text-font text-sm md:text-base">gerege@gmail.com</p>
          </div>
          {/* Social Media Section */}
          <div>
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Сошиал медиа
            </h3>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
              {" "}
              {/* Stacked on small screens */}
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <FaFacebook className="mr-1" /> {/* Facebook icon */}
                Facebook
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-blue-400 hover:text-blue-600"
              >
                <FaTwitter className="mr-1" /> {/* Twitter icon */}
                Twitter
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-pink-600 hover:text-pink-800"
              >
                <FaInstagram className="mr-1" /> {/* Instagram icon */}
                Instagram
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-lg md:text-xl font-semibold mb-3">
            Ажиллах цагийн хуваарь
          </h3>
          <p className="text-font text-sm md:text-base">
            Даваа - Баасан: 09:00 - 18:00
            <br />
            Бямба, Ням: Амарна
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
