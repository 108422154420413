import React, { useContext, useEffect, useState } from "react";

const ThemeContext = React.createContext({
  theme: "light",
  changeTheme: (theme) => {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

const ThemeProvider = ({ children }) => {
  const storeTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(storeTheme || "light");

  const changeTheme = (_theme) => {
    localStorage.setItem("theme", _theme);
    setTheme(_theme);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
