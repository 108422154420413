import Card from "../../../../components/Card";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import Button from "../../../../base-components/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Contract = ({ step, setStep }) => {

  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState(
    "https://pdfobject.com/pdf/sample.pdf"
  );
  // const [width, setWidth] = useState(600); // Default width
  // const [height, setHeight] = useState(400); // Default height

  const handleClick = () => {
    setStep(step + 1)

    navigate("/savings", { replace: true });
    toast.success("Амжилттай хуримтлал үүслээ!");
  };

  return (
    <div className="mt-5 grid gap-5">
      {/* PDF Viewer */}
      <Card>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div
            style={{
              height: `${400}px`,
            }}
            className="border rounded-md overflow-hidden"
          >
            <Viewer fileUrl={fileUrl} />
          </div>
        </Worker>
      </Card>

      {/* Custom Width/Height Controls */}
      {/* <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block mb-1">Width (px)</label>
          <input
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Height (px)</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
      </div> */}

      {/* Confirm Button */}
      <div className="flex justify-center mt-5">
        <Button onClick={handleClick}>Баталгаажуулах</Button>
      </div>
    </div>
  );
};

export default Contract;
