import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Make sure to import the necessary Swiper CSS
import { dispatch, useSelector } from "../../redux/store";
import { getProductList } from "../../redux/slices/product";
import CreateSavings from "./create-savings";

const ProductRecommendations = () => {
  const { products, loading } = useSelector((state) => state.product);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productImages = [
    "https://i.pinimg.com/enabled_hi/564x/d9/57/a4/d957a459a1434e9235b320fb37ae5773.jpg",
    "https://i.pinimg.com/enabled_hi/564x/90/bb/bb/90bbbbfd770343e1ce90810406460a8d.jpg",
    "https://i.pinimg.com/enabled_hi/564x/fd/19/18/fd191840e0448d130c824b516fdcd6ab.jpg",
    "https://i.pinimg.com/enabled_hi/564x/cc/1c/9f/cc1c9f11edcf64c3052a003ec29912bd.jpg",
  ];

  const navigate = useNavigate();

  console.log("productsproducts", products);

  // useEffect(() => {
  //   dispatch(getProductList());
  // }, [dispatch]);

  if (loading) return <>Loading...</>;
  return (
    <>
      <div className="bg-gray-200 p-4 rounded-lg shadow-md mt-4">
        <button className="outline-none">
          <h3 className="font-bold text-lg mb-4">
            Санал болгож буй бүтээгдэхүүн
          </h3>
        </button>
        <Swiper
          className="hidden-arrows"
          spaceBetween={20}
          style={{ zIndex: 0 }}
          slidesPerView={1} // Default view for mobile
          breakpoints={{
            640: {
              slidesPerView: 1, // 1 item on mobile devices (up to 640px)
            },
            768: {
              slidesPerView: 2, // 2 items on tablets (768px and up)
            },
            1024: {
              slidesPerView: 3, // 3 items on larger screens (1024px and up)
            },
          }}
          navigation // Keep navigation functionality
          pagination={{ clickable: true }}
        >
          {productImages.map((product, index) => (
            <SwiperSlide
              key={product.id}
              className="flex-shrink-0"
              onClick={() => {
                setIsOpenModal(true)
                // navigate(`/product-detail/${product.id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="bg-white rounded-lg shadow-md p-2">
                <img
                  src={productImages[index % productImages.length]}
                  alt={product.name}
                  className="w-full h-24 object-cover rounded-lg mb-2"
                />
                <p className="text-center font-semibold">{product.name}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <style jsx>{`
          .hidden-arrows .swiper-button-next,
          .hidden-arrows .swiper-button-prev {
            display: none; // Hide the navigation buttons
          }
        `}</style>
      </div>
      {isOpenModal && <CreateSavings
        isOpen={true}
        onClose={() => setIsOpenModal(false)}
      />}
    </>
  );
};

export default ProductRecommendations;
