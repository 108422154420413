import React, { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { registeredDevicesThunk } from "../../redux/slices/profile";
import { formatDate } from "../../utils/fomatter";

const RegisteredDevices = () => {
    const { registerDevices, registerDeviceLoading } = useSelector(
        (state) => state.profile
    );

    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        dispatch(registeredDevicesThunk());
    }, []);

    const totalPages = Math.ceil(registerDevices.length / itemsPerPage);

    const currentData = registerDevices.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Modal state
    const [selectedItem, setSelectedItem] = useState(null);

    const openModal = (item) => {
        setSelectedItem(item);
    };

    const closeModal = () => {
        setSelectedItem(null);
    };

    return (
        <div className="max-w-4xl mx-auto mt-5">
            <div className="bg-white shadow-md rounded-lg p-3">
                <h2 className="text-xl font-semibold mb-4">Хандалтын түүх</h2>
                {registerDeviceLoading ? (
                    "....Loading"
                ) : (
                    <>
                        <div className="overflow-x-auto">
                            <table className="w-full table-auto text-left border-collapse">
                                <thead>
                                    <tr className="text-gray-700 bg-gray-200">
                                        <th className="px-4 py-2">Огноо</th>
                                        <th className="px-4 py-2">Бүртгэсэн төхөөрөмжийн мэдээлэл</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                                            onClick={() => openModal(item)}
                                        >
                                            <td className="px-4 py-2">{formatDate(item.createdAt)}</td>
                                            <td className="px-4 py-2">{item.userAgent}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="flex justify-center mt-4 space-x-2">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                                (page) => (
                                    <button
                                        key={page}
                                        className={`px-3 py-1 rounded ${page === currentPage
                                            ? "bg-primary text-white"
                                            : "bg-gray-300 text-gray-600"
                                            }`}
                                        onClick={() => goToPage(page)}
                                    >
                                        {page}
                                    </button>
                                )
                            )}

                        </div>
                    </>
                )}
            </div>

            {/* Modal for details */}
            {/* {selectedItem && (
                <div className="fixed inset-0 primary primary flex items-center justify-center">
                    <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
                        <h3 className="text-lg font-semibold mb-4">
                            Төхөөрөмжийн дэлгэрэнгүй
                        </h3>
                        <p>
                            <strong>Огноо:</strong> {formatDate(selectedItem.createdAt)}
                        </p>
                        <p>
                            <strong>Төхөөрөмжийн мэдээлэл:</strong> {selectedItem.userAgent}
                        </p>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="primary text-white px-4 py-2 rounded"
                                onClick={closeModal}
                            >
                                Хаах
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default RegisteredDevices;
