import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProductService from "../../services/ProductService";
// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    error: null,
    success: false,
    products: [],
    product: null,
    detailLoading: false,
};

const slice = createSlice({
    name: "product",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        startDetailLoading(state) {
            state.detailLoading = true;
        },
        stopDetailLoading(state) {
            state.detailLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.loading = false;
            state.detailLoading = false;
            console.log("error ni yu ireed bna", action);
            state.error = action.payload;
        },
        initialStateProduct(state) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.products = [];
        },

        getProductDataSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.products = action.payload;
        },
        getProductDetailSuccess(state, action) {
            state.product = action.payload;
            state.detailLoading = false;
            state.error = null;
            state.success = false;
        },
        hideError(state) {
            state.error = null;
            state.otpResendSuccess = false;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hideError, hasError, initialStateProduct } =
    slice.actions;

// // ----------------------------------------------------------------------

export function getProductList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await ProductService.getProductList();
            console.log("getProductsList", response);
            dispatch(slice.actions.getProductDataSuccess(response.responseData));
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
        }
    };
}

export function getProductDetail(id) {
    return async () => {
        dispatch(slice.actions.startDetailLoading());
        try {
            const response = await ProductService.getProductDetail(id);
            console.log("getProductDetail", response);
            dispatch(slice.actions.getProductDetailSuccess(response?.responseData));
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
        }
    };
}
