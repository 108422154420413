import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProfileService from "../../services/ProfileService";
// ----------------------------------------------------------------------

const initialState = {
    accessDeviceLoading: false,
    accessDevices: [],
    error: null,
    success: false,
    registerDeviceLoading: false,
    registerDevices: [],

};

const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        startAccessDeviceLoading(state) {
            state.accessDeviceLoading = true;
        },
        stopAccessDeviceLoading(state) {
            state.accessDeviceLoading = false;
        },
        startRegisterDeviceLoading(state) {
            state.registerDeviceLoading = true;
        },
        stopRegisterDeviceLoading(state) {
            state.registerDeviceLoading = false;
        },
        hasError(state, action) {
            state.accessDeviceLoading = false;
            state.registerDeviceLoading = false;
            state.error = action.payload;
        },

        accessDeviceSuccess(state, action) {
            state.accessDeviceLoading = false;
            state.accessDevices = action.payload;
        },
        registerDeviceSuccess(state, action) {
            state.registerDeviceLoading = false;
            state.registerDevices = action.payload;
        },
        initialStateProfile(state) {
            state.accessDeviceLoading = false;
            state.error = null;
            state.success = false;
        },
        hideError(state) {
            state.error = null;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startAccessDeviceLoading, stopAccessDeviceLoading, hideError, initialStateProfile } =
    slice.actions;

// // ----------------------------------------------------------------------

export function accessHistoryThunk() {
    return async () => {
        dispatch(slice.actions.startAccessDeviceLoading());
        try {
            const response = await ProfileService.accessHistory();
            dispatch(slice.actions.accessDeviceSuccess(response.responseData));
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
        }
    };
}

export function registeredDevicesThunk() {
    return async () => {
        dispatch(slice.actions.startRegisterDeviceLoading());
        try {
            const response = await ProfileService.registeredDevices();
            dispatch(slice.actions.registerDeviceSuccess(response.responseData));
        } catch (error) {
            //eniig taviishuu
            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
        }
    };
}