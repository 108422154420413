import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";
import { TbCircleCheck } from "react-icons/tb";

const SavingsInfo = ({ step, setStep }) => {
  const handleClick = () => {
    setStep(step + 1);
  };

  return (
    <div className="grid gap-5 mt-5">
      <Card className="h-72 max-h-90 overflow-auto">
        <div className="grid gap-4">
          <Typography className="text-primary">
            Голомт банк дээрх 30 жилийн турш тогтмол хугацаанд тодорхой дүнгээр
            орлого хийе
          </Typography>
          <div className="flex items-center space-x-2">
            <TbCircleCheck size={25} stroke="#025867" />
            <Typography className="text-primary">Валют: Төгрөг</Typography>
          </div>
          <div className="flex items-center space-x-2">
            <TbCircleCheck size={25} stroke="#025867" />
            <Typography className="text-primary">
              8.10%-12.8% хүртэл хүүтэй
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <TbCircleCheck size={25} stroke="#025867" />
            <Typography className="text-primary">
              100’000 төг доод үлдэгдэл
            </Typography>
          </div>
        </div>
      </Card>
      <div className="flex justify-center mb-5">
        <Button disabled={step > 1} onClick={handleClick}>
          Хуримтлал нээх
        </Button>
      </div>
    </div>
  );
};

export default SavingsInfo;
