import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as CheckIcon } from "../../assets/icon/check.svg";
import { useLocale } from "../../contexts/LocaleContext";
import { twMerge } from "tailwind-merge";

const LanguageSelector = () => {
  const { locales, locale, changeLocale } = useLocale();

  const buttonStyle = [
    "flex items-center justify-center rounded-full bg-white p-2 cursor-pointer outline-none",
    "border border-stroke bg-white text-primary", // Default
    "focus:bg-primary-soft-while-pressing focus:text-gray", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-gray", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-inactive", // Disabled
    "w-10 h-10",
  ];
  return (
    <>
      <Listbox value={locale} onChange={changeLocale}>
        <div className="relative">
          <Listbox.Button className="relative rounded-full">
            <div className={twMerge(buttonStyle)}>
              <span className="text-sm font-bold">{locale.name}</span>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 w-fit overflow-auto rounded-xl border bg-white py-1 text-base shadow-lgfocus:outline-none sm:text-sm">
              {locales.map((l, localeIdx) => {
                const isActive = locale.key === l.key;
                return (
                  <Listbox.Option
                    key={localeIdx}
                    className={`relative cursor-default select-none py-2 pl-10 pr-4 text-primary ${
                      isActive ? "bg-gray-200" : ""
                    }`}
                    value={l}
                  >
                    <span
                      className={`block truncate ${
                        isActive ? "font-medium" : "font-normal"
                      }`}
                    >
                      {l.name}
                    </span>
                    {isActive ? (
                      <span className="absolute inset-y-0 left-0 flex pl-3 items-center text-primary">
                        <CheckIcon className="h-4 w-4" />
                      </span>
                    ) : null}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
};

export default LanguageSelector;
