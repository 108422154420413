import React, { useEffect, useState } from "react";
// import { dispatch, useSelector } from "../../redux/store";
// import { registeredDevicesThunk } from "../../redux/slices/profile";
// import { formatDate } from "../../utils/fomatter";

const DanResponse = () => {
    // const { registerDevices, registerDeviceLoading } = useSelector(
    //     (state) => state.profile
    // );

    // const [currentPage, setCurrentPage] = useState(1); // Current page state
    // const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        // dispatch(registeredDevicesThunk());
        // alert("claa dan serice");
    }, []);


    return (
        <div className="max-w-4xl mx-auto mt-5">
            Dan response
        </div>
    );
};

export default DanResponse;
