import { Select } from "@headlessui/react";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";
import Input from "../../../../base-components/Input";
import dayjs from "dayjs";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const savingsType = [
  {
    id: "golomt",
    value: "Голомт хуримтлал",
  },
  {
    id: "other",
    value: "Бусад",
  },
];

const years = ["1", "2", "5", "10", "15"];

const SavingsForm = ({ step, setStep }) => {
  const [selectedYear, setSelectedYear] = useState(years[3]);
  const onContinue = () => {
    setStep(step + 1);
  };

  console.log(step, "step");

  return (
    <div className="grid gap-5 mt-5">
      <div className="bg-white rounded-xl p-4 grid gap-4">
        <Typography className="text-primary">Хадгаламжийн төрөл</Typography>
        <Select
          className={
            "w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-primary text-sm"
          }
        >
          {savingsType.map((type) => (
            <option key={type.id} value={type.value}>
              <Typography variant="Label">{type.value}</Typography>
            </option>
          ))}
        </Select>
        <div className="w-full outline-none border border-stroke rounded-md p-2 py-1.5">
          <Typography variant="Label" className="text-primary">
            MNT
          </Typography>
        </div>
        <Select
          className={
            "w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm"
          }
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          {years.map((jil) => (
            <option key={jil} value={jil} className="text-primary">
              <Typography>{jil} жил</Typography>
            </option>
          ))}
        </Select>
        <Input size="sm" value="100,000" className="text-primary" />
        <Input
          size="sm"
          value={dayjs(new Date()).format("YYYY-MM-DD")}
          className="text-primary"
        />
        <Input
          size="sm"
          value={"Бодогдох хүү: " + "18%"}
          className="text-primary"
        />
        <Input
          size="sm"
          value={"Сүүлд хуримтлагдах дүн:  5,000,000,000"}
          className="text-primary"
        />
      </div>
      <div className="flex justify-center">
        <Button disabled={step !== 2} onClick={onContinue}>
          Үргэлжлүүлэх
        </Button>
      </div>
    </div>
  );
};

export default SavingsForm;
