import Service from "./BaseService";

const getProductList = (config) => Service.get(`/admin/product/list`, { ...config, nonLoading: true }, 8023);
const getProductDetail = (productId, config) => Service.get(`/admin/product/detail?productId=${productId}`, { ...config, nonLoading: true }, 8023);

const ProductService = {
  getProductList,
  getProductDetail,
}

export default ProductService;