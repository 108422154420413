import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";

const schedules = [
  { ognoo: "2024.12.01", dun: "200,000", huu: "10,000" },
  { ognoo: "2025.01.01", dun: "200,000", huu: "11,000" },
  { ognoo: "2025.02.01", dun: "200,000", huu: "12,000" },
];

const Schedule = ({ step, setStep }) => {
  return (
    <>
      <Card className="mt-5 grid gap-5">
        <Typography>Хуримтлал нэмэх хуваарь</Typography>
        {schedules.map((schedule) => (
          <div
            key={schedule.ognoo}
            className="bg-gray-200 rounded-lg p-3 grid gap-3"
          >
            <Typography>{schedule.ognoo}</Typography>
            <div className="grid grid-cols-2">
              <div>
                <Typography variant="Label" className="text-gray">
                  Нэмэх дүн
                </Typography>
                <Typography>{schedule.dun}₮</Typography>
              </div>
              <div>
                <Typography variant="Label" className="text-gray">
                  Хуримтлагдах хүү
                </Typography>
                <Typography>{schedule.huu}₮</Typography>
              </div>
            </div>
          </div>
        ))}
      </Card>
      <div className="flex items-center justify-center mt-5">
        <Button onClick={() => setStep(step + 1)}>Үргэлжлүүлэх</Button>
      </div>
    </>
  );
};

export default Schedule;
